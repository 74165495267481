import { instance } from '@/utils/http.js'

// 获取科目
export const getUserSubject = () => {
  return instance({
    url: '/api/v1/public/subjects',
    method: 'get',
  })
}

// 学生学习模式下的科目
export const getStudyModeSubject = function () {
  return instance({
    url: '/api/v1/user/study_subject',
    method: 'get'
  })
}

// 学生学习模式下的科目
export const getTestModeSubject = function () {
  return instance({
    url: '/api/v1/public/test_subjects',
    method: 'get'
  })
}

// 获取学生科目
export const getStudentSubject = function () {
  return instance({
    url: '/api/v1/cp/subject_list',
    method: 'get',
  })
}

// 获取学生科目
export const getReport = function (params) {
  return instance({
    url: '/api/v1/user/new_study_report',
    method: 'get',
    params
  })
}

// 设置目标分数
export const setHopeScore = function (data) {
  return instance({
    url: '/api/v1/user/set_user_hope_score',
    method: 'post',
    data
  })
}
//各科最大分
export const getMaxScore = function (params) {
  return instance({
    url: '/api/v1/user/max_score',
    method: 'get',
    params
  })
}