<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             @ok="exportBtn"
             :footer="null"
             @cancel="handleClose">
      <div class="head_img">
        <div class="text">你的{{edu_grade_id==1?'中考目标':"学科目标"}}</div>
        <div class="text1">我们好为你智能分析各考点的达标情况</div>
      </div>
      <a-form :model="form"
              ref="formRef"
              :labelCol="{span:6}">
        <a-row>
          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(1)&&subjectList.some(item=>item.subject_id==1)">
          <a-form-item label="语文：">
              <a-input-number v-model:value="subjectListData[0].score"
                              ref="1"
                              style="width:80%"
                              @blur="handleChange(subjectListData[0])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>

          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(2)&&subjectList.some(item=>item.subject_id==2)">
          <a-form-item label="数学：">
              <a-input-number v-model:value="subjectListData[1].score"
                              ref="2"
                              style="width:80%"
                              @blur="handleChange(subjectListData[1])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>

          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(3)&&subjectList.some(item=>item.subject_id==3)">
          <a-form-item label="英语：">
              <a-input-number v-model:value="subjectListData[2].score"
                              ref="3"
                              style="width:80%"
                              @blur="handleChange(subjectListData[2])"
                              label="描述文字"></a-input-number>

            </a-form-item>
          </a-col>
          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(4)&&subjectList.some(item=>item.subject_id==4)">
          <a-form-item label="物理：">
              <a-input-number v-model:value="subjectListData[3].score"
                              ref="4"
                              style="width:80%"
                              @blur="handleChange(subjectListData[3])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>

          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(5)&&subjectList.some(item=>item.subject_id==5)">
          <a-form-item label="化学：">
              <a-input-number v-model:value="subjectListData[5].score"
                              ref="5"
                              style="width:80%"
                              @blur="handleChange(subjectListData[5])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(6)&&subjectList.some(item=>item.subject_id==6)">
          <a-form-item label="历史：">
              <a-input-number v-model:value="subjectListData[4].score"
                              ref="6"
                              style="width:80%"
                              @blur="handleChange(subjectListData[4])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>

          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(7)&&subjectList.some(item=>item.subject_id==7)">
          <a-form-item label="道德与法治：">
              <a-input-number v-model:value="subjectListData[6].score"
                              ref="7"
                              style="width:80%"
                              @blur="handleChange(subjectListData[6])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(9)&&subjectList.some(item=>item.subject_id==9)">
          <a-form-item label="生物学：">
              <a-input-number v-model:value="subjectListData[7].score"
                              ref="9"
                              style="width:80%"
                              @blur="handleChange(subjectListData[7])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="24"
          v-if="scoreData && scoreData.hasOwnProperty(10)&&subjectList.some(item=>item.subject_id==10)">
          <a-form-item label="地理：">
              <a-input-number v-model:value="subjectListData[8].score"
                              ref="10"
                              style="width:80%"
                              @blur="handleChange(subjectListData[8])"
                              label="描述文字"></a-input-number>
            </a-form-item>
          </a-col>

        </a-row>
      </a-form>
      <div class="btn_groups">
        <div class="btn"
             @click="close">
          不再提示
        </div>

        <div class="btn1"
             @click="save">
          确 认
        </div>
      </div>
    </a-modal>
  </div>

</template>

<script setup>
import { getUserSubject, setHopeScore, getMaxScore } from '@/api/subject.js'
import { computed, onMounted } from "@vue/runtime-core";
import { ref, nextTick, getCurrentInstance, watch } from 'vue';
import { message } from 'ant-design-vue'
const instance = getCurrentInstance();
let dialogVisible = ref(false);
const subjectList = ref([]);
const form = ref({});
const messageData = ref([]);
const edu_grade_id = ref(null)
const subjectListData = ref([
  {
    subject_id: 1,
    subject_name: "语文",
    score: null,
  },
  {
    subject_id: 2,
    subject_name: "数学",
    score: null,
  },
  {
    subject_id: 3,
    subject_name: "英语",
    score: null,
  },
  {
    subject_id: 4,
    subject_name: "物理",
    score: null,
  },

  {
    subject_id: 6,
    subject_name: "历史",
    score: null,
  },
  {
    subject_id: 5,
    subject_name: "化学",
    score: null,
  },


  {
    subject_id: 7,
    subject_name: "思想政治",
    score: null,
  },
  {
    subject_id: 9,
    subject_name: "生物学",
    score: null,
  },
  {
    subject_id: 10,
    subject_name: "地理",
    score: null,
  },
]);
let emits = defineEmits(['updateScore'])
const scoreData = ref([]);
const isValid = ref(true);
onMounted(async () => {
  edu_grade_id.value = JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id 
  const { data: maxScore } = await getMaxScore()
  const { data } = await getUserSubject()
  scoreData.value = maxScore;
  let totalScore = 0; // 定义变量用于累加分数
  let subjectListDataWindow = [];
  // console.log('max', maxScore);

  subjectListDataWindow = data.list.filter(item => item.subject_id !== 13).map(item => {
    totalScore += item.score;

    // if ((item.subject_id === 1 || item.subject_id === 2) && item.score == 0) {
    //   item.score = 96; // 设置为130
    // } else if (item.score == 0) {
    //   item.score = 80; // 其他设为90
    // }

    return {
      subject_id: item.subject_id,
      score: item.score,
    }
  });
  // this.$EventBus.emit('totalScoreEdit', totalScore)
  window.localStorage.setItem('subjectListData', JSON.stringify(subjectListDataWindow))

  subjectList.value = JSON.parse(window.localStorage.getItem('subjectListData'))


  subjectListData.value.forEach(item => {
    subjectList.value.map(item2 => {
      if ((item2.subject_id == item.subject_id) && item.score == null) {

        item.score = item2.score
      }
    })
  })
  messageData.value = subjectListData.value
  messageData.value.forEach(item => {
    for (const maxitem in maxScore) {
       if (item.subject_id == maxitem) {
         item.maxScoredate = maxScore[maxitem];
       }
     }
  })



})

const focusInput = () => {


  nextTick(() => {

    if (instance) {
      const { proxy } = instance;
      const physicalSubjectId = JSON.parse(window.localStorage.getItem('physical_subjectId')) || 1;

      if (proxy && proxy.$refs[physicalSubjectId]) {
        proxy.$refs[physicalSubjectId].focus();
      } else {
        console.warn('指定的引用不存在或proxy为null');
      }
    } else {
      console.warn('当前组件实例为null');
    }
  });
};
const close = () => {
  dialogVisible.value = false;
  window.localStorage.setItem('score_dialog', false);
};
const handleClose = () => {
  dialogVisible.value = false;
};
const handleChange = (val) => {
  let temp = val;
  isValid.value = true;


  if ([1, 2].includes(val.subject_id)) {

    if (temp.score < 30) {
      message.error(`${subjectListData.value.find(item => item.subject_id === val.subject_id).subject_name}目标分数最底：30分`);
      isValid.value = false;
    } else if (temp.score > messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate) {
      message.error(`${subjectListData.value.find(item => item.subject_id === val.subject_id).subject_name}目标分数最高：${messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate}分`);
      isValid.value = false;
    }
  } else {
    if (temp.score < 25) {
      message.error(`${subjectListData.value.find(item => item.subject_id === val.subject_id).subject_name}目标分数最底：25分`);
      isValid.value = false;
    } else if (temp.score > messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate) {
      message.error(`${subjectListData.value.find(item => item.subject_id === val.subject_id).subject_name}目标分数最高：${messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate}分`);
      isValid.value = false;
    }
  }
  return isValid.value;
};
const subvalidate = (val) => {
  isValid.value = true;
  if (val.subject_id == 1 || val.subject_id == 2) {
    if (val.score < 30) {
      message.error(`${subjectListData.value.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：30分`);
      isValid.value = false;  // 设置为无效
    } else if (val.score > messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate) {
      message.error(`${subjectListData.value.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：${messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate}分`);
      isValid.value = false;  // 设置为无效
    }
  } else {

    if (val.score < 25) {
      message.error(`${subjectListData.value.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：25分`);
      isValid.value = false;  // 设置为无效
    } else if (val.score > messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate) {
      message.error(`${subjectListData.value.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：${messageData.value.find(item => item.subject_id === val.subject_id).maxScoredate}分`);
      isValid.value = false;  // 设置为无效
    }
  }

  return isValid.value; // 返回最终验证状态
};
const save = async () => {
  subjectList.value.forEach(item2 => {
    subjectListData.value.forEach(item => {
      if (item2.subject_id === item.subject_id) {
        item2.score = item.score;
      }
    });
  });
  subjectList.value = subjectList.value.filter(item => {
    return Object.keys(scoreData.value).includes(item.subject_id.toString());
  });

  const validationPromises = subjectList.value.map(item => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(subvalidate(item));
      }, 50);
    });
  });

  const results = await Promise.all(validationPromises);
  const isTrue = results.some(result => result === false);
  if (isTrue) return;

  let data = { subject_score: subjectList.value };

  try {
    const res = await setHopeScore(data);
    let totalScore = 0;
    let filteredSubjects = subjectList.value.filter(item => item.subject_id !== 13).map(item => {
      totalScore += item.score;
      if ([7, 12, 11].includes(item.subject_id) && item.score === 0) {
        item.score = 130;
      } else if (item.score === 0) {
        item.score = 90;
      }
      return { subject_id: item.subject_id, score: item.score };
    });

    // this.$EventBus.emit('totalScoreEdit', totalScore);
    window.localStorage.setItem('subjectListData', JSON.stringify(filteredSubjects));
    window.localStorage.setItem('totalScore', totalScore);

    filteredSubjects.forEach(item => {
      const physicalSubjectId = JSON.parse(window.localStorage.getItem('physical_subjectId'));
      if (physicalSubjectId && item.subject_id === physicalSubjectId.subject_id) {
        this.$parent.paper_duration = item.score;
        physicalSubjectId.score = item.score;
        window.localStorage.setItem('physical_subjectId', JSON.stringify(physicalSubjectId));
      }
    });

    message.success('目标分数设置成功！');

    emits('updateScore')
    handleClose();
  } catch (error) {
    console.error(error);
  }

};
watch(() => dialogVisible.value, () => {
  if (dialogVisible.value) {
    focusInput();
  }
}, { deep: true })
defineExpose({
  dialogVisible
})

</script>

<style lang="scss" >
.ant-modal-body {
  padding: 24px 24px 0px 24px;
}
.head_img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32rem;
  margin-bottom: 32rem;
  .text {
    color: var(--light-333333, var(--neutral-color-333333, #333));
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  }
  .text1 {
    color: var(--neutral-color-666666, #666);
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
}
.btn_groups {
  display: flex;
  border-top: 1px solid var(--neutral-color-eeeeee, #eee);
  height: 80px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--light-333333, var(--neutral-color-333333, #333));
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    border-right: 1px solid var(--neutral-color-eeeeee, #eee);
  }
  .btn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--light-333333, var(--neutral-color-333333, #2196f3));
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
}
</style>